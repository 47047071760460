import React, { useEffect, useState, useRef } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Sticky from 'react-sticky-el'
import PropTypes from 'prop-types'
import { Grid } from 'antd'

import Form from '../Form'
import * as styles from './sidebar.module.scss'

const ContentSidebar = ({ title, headings, withForm, priceData }) => {
    const [activeId, setActiveId] = useState()
    const { useBreakpoint } = Grid
    const { md } = useBreakpoint()
    const isPriceSidebar = priceData && priceData.length > 0

    const useIntersectionObserver = setActiveId => {
        // https://www.emgoto.com/react-table-of-contents/
        const headingElementsRef = useRef({})

        useEffect(() => {
            const callback = headings => {
                headingElementsRef.current = headings.reduce((map, headingElement) => {
                    map[headingElement.target.id] = headingElement
                    return map
                }, headingElementsRef.current)

                const visibleHeadings = []

                Object.keys(headingElementsRef.current).forEach(key => {
                    const headingElement = headingElementsRef.current[key]
                    if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
                })

                const getIndexFromId = id => headingElements.findIndex(heading => heading.id === id)

                if (visibleHeadings.length === 1) {
                    if (visibleHeadings[0].target && visibleHeadings[0].target.id) {
                        const hiddenElement = document.querySelector(`li[data-id=${visibleHeadings[0].target.id}]`)

                        setActiveId(visibleHeadings[0].target.id)

                        if (hiddenElement && hiddenElement.classList.contains(styles.active)) {
                            hiddenElement.scrollIntoView(true) // { block: 'nearest', behavior: 'smooth' }
                        }
                    }
                } else if (visibleHeadings.length > 1) {
                    const sortedVisibleHeadings = visibleHeadings.sort(
                        (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
                    )

                    if (sortedVisibleHeadings[0].target && sortedVisibleHeadings[0].target.id) {
                        const hiddenElement = document.querySelector(
                            `li[data-id=${sortedVisibleHeadings[0].target.id}]`
                        )

                        setActiveId(sortedVisibleHeadings[0].target.id)
                    }
                }
            }

            const observer = new IntersectionObserver(callback, {
                rootMargin: '0% 0% 0% 0%',
            })

            const headingElements = Array.from(
                document.querySelectorAll('#content h2, #content h3, #content h4, #content h5, #content h6')
            )

            headingElements.forEach(element => observer.observe(element))

            return () => observer.disconnect()
        }, [setActiveId])
    }

    useIntersectionObserver(setActiveId)

    return (
        <aside className={styles.wrapper}>
            <Sticky disabled={!md} boundaryElement={isPriceSidebar ? '#price-data' : '#section'}>
                {isPriceSidebar ? (
                    <div className={styles.innerPrice} style={{ margin: `115px 0px 0px 0px` }}>
                        <ul className={styles.list}>
                            {priceData.map((item, index) => {
                                return (
                                    <li key={item.id} className={md && activeId === item.id ? styles.active : ''}>
                                        <div
                                            className={styles.listItemSubtitle}
                                            dangerouslySetInnerHTML={{ __html: item.title }}
                                        />
                                        <div className={styles.listItemPrice}>
                                            € {item.price ? item.price : '---'}
                                            {item.perMonth && <sub>/мес</sub>}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                ) : headings.length > 0 ? (
                    <div className={withForm ? styles.innerWithForm : styles.inner}>
                        <h2 className={styles.title}>{withForm && title ? title : 'Содержание статьи'}</h2>
                        <ul className={styles.list}>
                            {headings.map((item, i) => {
                                return (
                                    <li
                                        key={item.id}
                                        data-id={item.id}
                                        className={md && activeId === item.id ? styles.active : ''}
                                    >
                                        <a
                                            href={'#' + item.id}
                                            onClick={e => {
                                                e.preventDefault()
                                                scrollTo('#' + item.id, 'start')
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                        {withForm && (
                            <>
                                <h2 className={styles.title}>Получить презентацию о продукте</h2>
                                <Form
                                    layout='column'
                                    formType='sidebar'
                                    formId={`4c89d7a9-b2ca-4f2d-a4c2-bce9a67193c1`}
                                    formName='sidebar-contact'
                                />
                            </>
                        )}
                    </div>
                ) : null}
            </Sticky>
        </aside>
    )
}

ContentSidebar.propTypes = {
    title: PropTypes.string,
    headings: PropTypes.array,
    withForm: PropTypes.bool,
}

ContentSidebar.defaultProps = {
    title: ``,
    headings: [],
    withForm: true,
}

export default ContentSidebar
