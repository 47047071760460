import React from 'react'
import { Link } from 'gatsby'

import * as styles from './tags.module.scss'

const CategoriesTags = ({ categories }) => {
    return categories.length > 0 ? (
        <ul className={styles.list}>
            {categories.map(item => {
                return (
                    <li key={item.id} className={styles.item}>
                        <Link to={item.uri}>{item.name}</Link>
                    </li>
                )
            })}
        </ul>
    ) : null
}

export default CategoriesTags
