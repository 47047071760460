import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import ContentSidebar from '../ContentSidebar'
import * as styles from './content.module.scss'
import SocialSharer from '../SocialSharer'
import CategoriesTags from '../CategoriesTags'

const ContentSection = props => {
    const { date, uri, categories, content, title, withSidebar } = props
    const isPost = !!date
    const [sidebarLinks, setSidebarLinks] = useState([])

    useEffect(() => {
        content && filterContent(content)
    }, [])

    const filterContent = content => {
        const e = document.createElement('div')
        e.innerHTML = content

        e.childNodes.forEach(node => {
            const attrs = node.attributes

            if (attrs) {
                for (let i = attrs.length - 1; i >= 0; i--) {
                    if (attrs[i].name === 'id' && attrs[i].value !== '') {
                        setSidebarLinks(prevState => [
                            ...prevState,
                            {
                                id: node.id,
                                title: node.innerHTML,
                            },
                        ])
                    }
                }
            }
        })
    }

    return (
        <article className={styles.page} id='section'>
            <div className={styles.pageInner} id='content'>
                {parse(content)}
            </div>
            {withSidebar && <ContentSidebar title={title} headings={sidebarLinks} withForm={!isPost} />}
            {isPost && (
                <div className={styles.extra}>
                    <CategoriesTags categories={categories.nodes} />
                    <SocialSharer postUri={uri} />
                </div>
            )}
        </article>
    )
}

export default ContentSection
