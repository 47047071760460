import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { ClockCircleOutlined } from '@ant-design/icons'
import parse from 'html-react-parser'

import ScrollDownIcon from '../../assets/svg/scroll-down.inline.svg'
import ArrowRightIcon from '../../assets/svg/arrow-right.inline.svg'

import * as styles from './hero.module.scss'

const Hero = props => {
    const {
        heroSectionLeftSide: { heroSectionTitle, heroSectionDescription, heroSectionLink, heroSectionBreadcrumbs },
        heroSectionRightSide: { heroSectionImage },
    } = props.data
    const { date, readingMinutes } = props
    const backgroundImage = heroSectionImage ? getImage(heroSectionImage.localFile) : null

    return (
        <section className={styles.wrapper + ' global-full-width'}>
            <div className={styles.column}>
                {heroSectionBreadcrumbs && props.breadcrumb && (
                    <Breadcrumb
                        crumbs={props.breadcrumb.crumbs}
                        crumbSeparator=' / '
                        disableLinks={[props.breadcrumb.location]}
                        crumbLabel={props.breadcrumbLabel}
                    />
                )}
                {heroSectionTitle && <h1 className={styles.title + ' global-title-h1'}>{parse(heroSectionTitle)}</h1>}
                {heroSectionDescription && <p className={styles.description}>{parse(heroSectionDescription)}</p>}
                {heroSectionLink && heroSectionLink.url ? (
                    heroSectionLink.url === '#' ? (
                        <button
                            className={'global-button global-button--scroll'}
                            onClick={() => scrollTo('#section', 'start')}
                        >
                            {heroSectionLink.title}
                            <ScrollDownIcon />
                        </button>
                    ) : (
                        <Link
                            to={heroSectionLink.url}
                            className={styles.button + ' global-button global-button--rounded global-button--xl'}
                        >
                            {heroSectionLink.title}
                            <ArrowRightIcon />
                        </Link>
                    )
                ) : null}
                {date && (
                    <div className={styles.extra}>
                        {readingMinutes && (
                            <p style={{ marginRight: 10 }}>
                                <ClockCircleOutlined style={{ marginRight: 6 }} />
                                {Math.round(readingMinutes)} мин
                            </p>
                        )}
                        <p>Опубликовано: {date}</p>
                    </div>
                )}
            </div>
            <div className={styles.column}>
                {typeof backgroundImage === 'object' && backgroundImage !== null && (
                    <GatsbyImage loading='eager' image={backgroundImage} alt='Hero image' />
                )}
            </div>
        </section>
    )
}

export default Hero
